import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./styles/custom.sass";
import ThankPage from "./components/ThankPage";

function App() {
  const Nav = lazy(() => import("./components/Nav"));
  const Footer = lazy(() => import("./components/Footer"));
  const HomePage = lazy(() => import("./components/HomePage"));
  const PostAIJob = lazy(() => import("./components/PostAIJob"));
  const GPTPrompts = lazy(() => import("./components/GPTPrompts"));
  const CookiePage = lazy(() => import("./components/CookiePage"));
  const PrivacyPage = lazy(() => import("./components/PrivacyPage"));
  const SingleJob = lazy(() => import("./components/SingleJob"));
  const NotFound = lazy(() => import("./components/NotFound"));

  return (
    <BrowserRouter>
      <div className="app">
        <Suspense
          fallback={
            <div className="w-100 h-100 bg-dark c-white flex jcc aic loading">
              Зачекайте...
            </div>
          }
        >
          <Nav />
          <div className="bg-all">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/gpt-prompts" element={<GPTPrompts />} />
              <Route path="/post-ai-job" element={<PostAIJob />} />
              <Route path="/privacy" element={<PrivacyPage />} />
              <Route path="/cookie" element={<CookiePage />} />
              <Route path={"/:position/:id"} element={<SingleJob />} />
              <Route path={"/thank-page"} element={<ThankPage />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </div>
          <Footer />
        </Suspense>
      </div>
    </BrowserRouter>
  );
}

export default App;
