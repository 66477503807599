import { useNavigate } from "react-router-dom/dist";

const ThankPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <div
        style={{
          height: "500px",
          marginTop: "95px",
          padding: "60px 25px",
          textAlign: "center",
        }}
      >
        <div
          style={{ fontSize: "36px", marginBottom: "40px", marginTop: "30px" }}
        >
          Дякую за відправку
        </div>
        <div style={{ fontSize: "16px", lineHeight: "30px" }}>
          Вакансії модеруються,
          <br /> після перевірки на належний вигляд - ваша вакансія буде
          опублікована.
        </div>
        <button
          onClick={() => navigate("/")}
          style={{
            padding: "15px 20px",
            marginTop: "60px",
            border: "1px solid rgb(0,0,0,0.1)",
            borderRadius: "8px",
            background: "#f9ecff",
            fontWeight: "500",
          }}
        >
          Повернутись на головку
        </button>
      </div>
    </>
  );
};

export default ThankPage;
